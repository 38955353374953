import * as api from '@/api/cpiApplications';
import * as logger from 'loglevel';

logger.setDefaultLevel(process.env.VUE_APP_LOG_LEVEL || 'error');

const state = {
  cpiApplication: {},
  cpiApplications: [],
};

const getters = {
  cpiApplication: state => state.cpiApplication,
};

const mutations = {
  setCpiApplication(state, cpiApplication) {
    state.cpiApplication = {
      ...state,
      ...cpiApplication,
    };
  },
  setCpiApplications(state, cpiApplications) {
    state.cpiApplications = cpiApplications;
  },
  setCpiApplicationStatus(state, status) {
    state.cpiApplication = {
      ...state.cpiApplication,
      status,
    };
  },
  setCpiApplicationTier(state, tier) {
    state.cpiApplication.tier = tier;
  },
};

const actions = {
  async listCpiApplications({ commit }, queryParams) {
    try {
      const { data } = await api.listCpiApplications(queryParams);
      commit('setCpiApplications', data.results);
      return data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
  async getCpiApplication({ commit }, applicationId) {
    try {
      const { data } = await api.getCpiApplication(applicationId);
      commit('setCpiApplication', data);
      return data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
  async saveCpiApplication({ commit, state }, application) {
    try {
      let updatedApplication = application;
      const applicationId = state.cpiApplication.id;
      if (applicationId) {
        await api.updateCpiApplication(applicationId, application);
        const { data } = await api.getCpiApplication(applicationId);
        updatedApplication = data;
      } else {
        const { data } = await api.createCpiApplication(application);
        updatedApplication = data;
      }

      commit('setCpiApplication', { ...updatedApplication });
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
  async discardApplication({ dispatch }, applicationId) {
    try {
      await api.deleteCpiApplication(applicationId);
      await dispatch('getCpiApplication', applicationId);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
  async submitApplication({ dispatch }, applicationId) {
    try {
      await api.submitApplication(applicationId);
      await dispatch('getCpiApplication', applicationId);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },

  async finalizeApplication({ commit }, { applicationId, status, tier }) {
    try {
      await api.finalizeApplication(applicationId, status, tier);
      commit('setCpiApplicationStatus', status);
      commit('setCpiApplicationTier', tier);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
  async archiveApplication({ dispatch }, applicationId) {
    try {
      await api.archiveCpiApplication(applicationId);
      await dispatch('listCpiApplications', applicationId);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
