import * as Api from '@/api/markets';
import * as logger from 'loglevel';

logger.setDefaultLevel(process.env.VUE_APP_LOG_LEVEL || 'error');

const state = {
  markets: [],
};

const mutations = {
  setMarkets(state, markets) {
    state.markets = markets;
  },
};

const actions = {
  async getMarkets({ commit }, applicationId) {
    return Api.getMarkets(applicationId)
      .then(markets => {
        commit('setMarkets', markets.data.reverse());
      })
      .catch(error => {
        logger.error(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
