import axios from 'axios';

const baseUrl = 'api/contracts';

export function getContracts({ vendorId }) {
  return axios.get(baseUrl, {
    params: { vendorId, currentPage: 1, pageSize: 200 },
  });
}

export function addContract(contract) {
  return axios.post(baseUrl, contract);
}

export function updateContract(contractId, payload, params = null) {
  return axios.put(`${baseUrl}/${contractId}`, payload, { params });
}

export function updateContractStatus(contractId, status) {
  return axios.put(`${baseUrl}/${contractId}?updateStatusOnly=true`, {
    status,
  });
}

export function acceptContractRequest(contractId) {
  return axios.post(`${baseUrl}/${contractId}/accept-request`);
}

export function denyContractRequest(contractId) {
  return axios.post(`${baseUrl}/${contractId}/deny-request`);
}

export function uploadContracts(payload) {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (!value) return;
    formData.append(key, value);
  });
  return axios.post(`${baseUrl}/bulk-uploads`, formData);
}

export function sendInvitationEmail(contractId) {
  return axios.post(`${baseUrl}/invitation/${contractId}`);
}
