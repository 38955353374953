import Vue from 'vue';

import store from '@/store/store';
import router from '@/router';
import App from '@/App';

import VueCurrencyInput from 'vue-currency-input';
import currency from '@/filters/currency';
import vuetify from '@/plugins/vuetify';
import axios from 'axios';
import '@/api';
import '@/filters/global';
import '@mdi/font/css/materialdesignicons.min.css';

import uiMessages from '@/i18n';

import {
  initLogger,
  initAppEventBus,
  initGlobalComponents,
  initMoment,
  initVueI18n,
} from '@aon/cfs-components/src/initialize';

import IpCompany from '@aon/cfs-company';
import IpInsurance from '@aon/cfs-insurance';
import IpComponents from '@aon/cfs-components';
import IpUser, { AUTH_CONSTANTS, getAccessToken } from '@aon/cfs-user';
import Vuelidate from 'vuelidate';

Vue.config.devtools = process.env.VUE_APP_DEV_TOOLS || false;
Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(IpInsurance, { insuranceApiInstance: axios, store });
Vue.use(IpComponents, { store });
Vue.use(IpUser, { store });

Vue.use(VueCurrencyInput, {
  globalOptions: { currency: 'USD' },
});
Vue.filter('currency', currency);

(async () => {
  initLogger({
    token: await getAccessToken(),
    tokenUpdatedEvent: AUTH_CONSTANTS.TOKEN_UPDATED,
    getToken: getAccessToken,
  });
  initAppEventBus();
  initGlobalComponents();
  initMoment();

  new Vue({
    i18n: initVueI18n(
      uiMessages,
      IpCompany.translations,
      IpInsurance.translations,
      IpUser.translations
    ),
    router,
    store,
    vuetify,
    beforeCreate() {
      this.$store.commit('initializeStore');
    },
    render: h => h(App),
  }).$mount('#app');
})();
