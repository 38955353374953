import { NOT_FOUND } from '@aon/cfs-components/src/lib/constants/route-names';

export async function requiresRole(to, from, next, store) {
  if (to.matched.some(record => record.meta.requiresRole)) {
    const { permissions } = store.state.user.user;
    const userRoles = permissions.accountPermissions.roles;

    if (!userRoles.some(r => to.meta.requiresRole.includes(r))) {
      if (userRoles.includes('BROKER_REVIEWER')) {
        return { name: 'COI Review' };
      }

      return { name: NOT_FOUND };
    }

    return next();
  }
}
