import axios from 'axios';

export function getQuotes(applicationId) {
  return axios.get(`api/applications/${applicationId}/quotes`);
}

export function getQuote(quoteId) {
  return axios.get(`api/quotes/${quoteId}`);
}

export function getProposalQuotes(appId) {
  return axios.get(`api/applications/${appId}/proposal-quotes`);
}

export function updateQuote(quoteId, quote) {
  return axios.put(`api/quotes/${quoteId}`, quote);
}

export function requestQuotes(
  quoteIds,
  coverageAmount,
  insuranceType,
  companyName,
  responseDate
) {
  const requestBody = {
    quoteIds,
    coverageAmount,
    insuranceType,
    companyName,
    responseDate,
  };

  return axios.post(`api/quotes/request`, requestBody);
}

export function createQuote(quote) {
  return axios.post(`api/quotes`, quote);
}

export function getQuoteDocument(quoteId) {
  return axios.get(`api/quotes/${quoteId}/document`);
}

export function uploadQuoteDocument(payload) {
  const formData = new FormData();
  formData.append(
    'file',
    new Blob([payload.file], { type: 'application/pdf' }),
    payload.file.name
  );
  formData.append('tenantId', payload.tenantId);
  return axios.post(`api/quotes/${payload.quoteId}/document`, formData);
}

export function removeQuoteDocument(quoteId) {
  return axios.delete(`api/quotes/${quoteId}/document`);
}
