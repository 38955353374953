import { addPrefix } from '@aon/cfs-utils/lib/translation';
import coiReview from './coiReview.json';
import coverageRequest from './coverageRequest.json';
import coverageRequests from './coverageRequests.json';
import cpiApplication from './cpiApplication.json';
import cpiApplicationList from './cpiApplicationList.json';
import cpiApplicationListAlert from './cpiApplicationListAlert.json';
import dataTables from './dataTables';
import errors from './errors.json';
import finalizePolicy from './finalizePolicy.json';
import forms from './forms.json';
import homeDetails from './homeDetails.json';
import identificationInformation from './identificationInformation.json';
import leftDrawer from './leftDrawer.json';
import mappings from './mappings.json';
import markets from './markets.json';
import pagination from './pagination.json';
import policies from './policies.json';
import proposal from './proposal.json';
import quotes from './quotes.json';
import cpiOpportunityList from './cpiOpportunityList.json';
import cpiOpportunity from './cpiOpportunity.json';
import definitionTooltips from './definition-tooltips.json';
import entityHeader from './entity-header.json';
import corScoreRadarWidget from './cor-score-radar-widget.json';
import cpiMetricQuestions from './cpiMetricQuestions.json';
import insurancePolicyModal from './insurancePolicyModal.json';
import endorsements from './endorsements.json';
import vendors from './vendors.json';
import vendorStatuses from './vendorStatuses.json';

export default addPrefix({
  coiReview,
  corScoreRadarWidget,
  coverageRequest,
  coverageRequests,
  cpiApplication,
  cpiApplicationList,
  cpiApplicationListAlert,
  cpiMetricQuestions,
  dataTables,
  definitionTooltips,
  entityHeader,
  errors,
  finalizePolicy,
  forms,
  homeDetails,
  identificationInformation,
  leftDrawer,
  mappings,
  markets,
  pagination,
  policies,
  proposal,
  quotes,
  cpiOpportunityList,
  cpiOpportunity,
  insurancePolicyModal,
  endorsements,
  vendors,
  vendorStatuses,
});
