import * as Api from '@/api/contracts';
import * as logger from 'loglevel';

logger.setDefaultLevel(process.env.VUE_APP_LOG_LEVEL || 'error');

const state = {
  contracts: {},
};

const getters = {
  getContractsById: state => vendorId => {
    return state.contracts[vendorId] ? state.contracts[vendorId] : [];
  },
};

const mutations = {
  updateContracts(state, { vendorId, contracts }) {
    state.contracts = {
      ...state.contracts,
      [vendorId]: contracts,
    };
  },

  putContract(state, { vendorId, contract }) {
    const vendorContracts = [...(state.contracts[vendorId] || []), contract];
    delete state.contracts[vendorId];

    state.contracts = {
      ...state.contracts,
      [vendorId]: vendorContracts,
    };
  },
};

const actions = {
  async getContractsForVendor({ commit }, vendorId) {
    try {
      const response = await Api.getContracts({
        vendorId,
      });
      commit('updateContracts', { vendorId, contracts: response.data.results });
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },

  async addContract({ commit, rootState }, contract) {
    contract.clientId = rootState.clients.clientId;

    try {
      await Api.addContract(contract);

      if (contract.vendorId) {
        commit('putContract', {
          vendorId: contract.vendorId,
          contract: { ...contract, status: 'accepted' },
        });
      }
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
