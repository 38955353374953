import * as Api from '@/api/quotes';
import * as logger from 'loglevel';

logger.setDefaultLevel(process.env.VUE_APP_LOG_LEVEL || 'error');

const state = {
  quotes: [],
};

const mutations = {
  setQuotes(state, quotes) {
    state.quotes = quotes;
  },
};

const actions = {
  async getQuotes({ commit }, applicationId) {
    return Api.getQuotes(applicationId)
      .then(quotes => {
        commit('setQuotes', quotes.data.reverse());
      })
      .catch(error => {
        logger.error(error);
        throw error;
      });
  },
  async getProposalQuotes({}, applicationId) {
    try {
      const response = await Api.getProposalQuotes(applicationId);
      return response.data;
    } catch (err) {
      logger.error(err.response || err);
      throw err;
    }
  },
  async updateQuote({}, quote) {
    return Api.updateQuote(quote.id, quote)
      .then(res => {
        return res;
      })
      .catch(err => {
        logger.error(err);
        throw err;
      });
  },
  async requestQuotes({}, payload) {
    const {
      quoteIds,
      coverageAmount,
      insuranceType,
      companyName,
      responseDate,
    } = payload;

    return Api.requestQuotes(
      quoteIds,
      coverageAmount,
      insuranceType,
      companyName,
      responseDate
    )
      .then(res => {
        return res;
      })
      .catch(err => {
        logger.error(err);
        throw err;
      });
  },
  async createQuote({}, quote) {
    return Api.createQuote(quote)
      .then(res => {
        return res;
      })
      .catch(err => {
        logger.error(err);
        throw err;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
