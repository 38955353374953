const currency = {
  USD: currencyFormatter('en-US', 'USD'),
};

function currencyFormatter(locale, type) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: type,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
}

export default function currencyFilter(value, currencyType = 'USD') {
  if (typeof value !== 'number') return value;

  const formatter = currency[currencyType];
  if (!formatter) return value;

  return formatter.format(value);
}
