import {
  ERROR,
  NOT_FOUND,
} from '@aon/cfs-components/src/lib/constants/route-names';
import { authRoutes } from '@aon/cfs-user';
import ROUTE_ROLE_ACCESS from '@/lib/constants/routeRoleAccess';
import { PERMISSION_REDIRECT } from '@aon/cfs-user/src/constants/routes';

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

function lazyLoadSubdirectory(path, view) {
  return () => import(`@/views/${path}/${view}.vue`);
}

function lazyLoadShared(view) {
  return () => import(`@aon/cfs-components/src/views/${view}`);
}

export const routes = [
  ...authRoutes,
  {
    path: '/',
    name: 'Broker Home',
    component: lazyLoad('Home'),
    meta: {
      requiresAuth: true,
      requiresRole: ROUTE_ROLE_ACCESS.HOME,
    },
  },
  {
    path: '/coverage-requests',
    name: 'Coverage Requests',
    component: lazyLoad('CoverageRequests'),
    meta: {
      requiresAuth: true,
      requiresRole: ROUTE_ROLE_ACCESS.COVERAGE_REQUESTS,
    },
  },
  {
    path: '/coverage-requests/:tenantId/:type/:id',
    name: 'Coverage Request',
    component: lazyLoad('CoverageRequest'),
    meta: {
      requiresAuth: true,
      requiresRole: ROUTE_ROLE_ACCESS.COVERAGE_REQUEST,
    },
  },
  {
    path: '/policies',
    name: 'Policies',
    component: lazyLoad('Policies'),
    meta: {
      requiresAuth: true,
      requiresRole: ROUTE_ROLE_ACCESS.POLICIES,
    },
  },
  {
    path: '/coi-review',
    name: 'COI Review',
    component: lazyLoad('CoiReview'),
    meta: {
      requiresAuth: true,
      requiresRole: ROUTE_ROLE_ACCESS.COI_REVIEW,
    },
  },
  {
    path: '/cpi-opportunities',
    name: 'CPI Opportunities',
    component: lazyLoadSubdirectory('CpiOpportunity', 'CpiOpportunityList'),
    meta: {
      requiresAuth: true,
      featureFlag: {
        name: 'cpi-lending-prototype',
        destination: NOT_FOUND,
      },
    },
  },
  {
    path: '/cpi-opportunities/:id',
    name: 'CPI Opportunity',
    component: lazyLoadSubdirectory('CpiOpportunity', 'CpiOpportunity'),
    meta: {
      requiresAuth: true,
      featureFlag: {
        name: 'cpi-lending-prototype',
        destination: NOT_FOUND,
      },
    },
  },
  {
    path: '/cpi-opportunities-v2',
    name: 'CPI Opportunities v2',
    component: lazyLoadSubdirectory('CpiOpportunity', 'CpiOpportunityListV2'),
    meta: {
      requiresAuth: true,
      featureFlag: {
        name: 'cpi-opportunity-v2',
        destination: NOT_FOUND,
      },
    },
  },
  {
    path: '/cpi-opportunities-v2/:id',
    name: 'CPI Opportunity v2',
    component: lazyLoadSubdirectory('CpiOpportunity', 'CpiOpportunityV2'),
    meta: {
      requiresAuth: true,
      featureFlag: {
        name: 'cpi-opportunity-v2',
        destination: NOT_FOUND,
      },
    },
  },
  {
    path: '/cpi-presentation',
    name: 'CPI Presentation',
    component: lazyLoadSubdirectory('CpiOpportunity', 'Presentation'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/unauthorized',
    name: PERMISSION_REDIRECT,
    component: () => import('@aon/cfs-user/src/views/PermissionRedirect.vue'),
    meta: { fullScreen: true },
  },
  {
    path: '/error',
    name: ERROR,
    component: lazyLoadShared('Error'),
  },
  {
    path: '*',
    name: NOT_FOUND,
    component: lazyLoadShared('404'),
  },
  {
    path: '/vendors',
    name: 'Vendors',
    component: lazyLoad('Vendors'),
    meta: {
      requiresAuth: true,
      requiresRole: ROUTE_ROLE_ACCESS.POLICIES,
    },
  },
];
