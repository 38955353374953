export const HOME = ['BROKER_USER', 'BROKER_ADMIN'];
export const COVERAGE_REQUESTS = ['BROKER_USER', 'BROKER_ADMIN'];
export const COVERAGE_REQUEST = ['BROKER_USER', 'BROKER_ADMIN'];
export const POLICIES = ['BROKER_USER', 'BROKER_ADMIN'];
export const COI_REVIEW = ['BROKER_USER', 'BROKER_ADMIN', 'BROKER_REVIEWER'];
export const CPI_APPLICATIONS = ['BROKER_USER', 'BROKER_ADMIN'];
export const CPI_APPLICATION = ['BROKER_USER', 'BROKER_ADMIN'];
export const CPI_APPLICATION_V2 = ['BROKER_USER', 'BROKER_ADMIN'];
export const VENDORS = ['BROKER_VENDORS', 'BROKER_ADMIN'];

const ROUTE_ROLE_ACCESS = {
  HOME,
  COVERAGE_REQUESTS,
  COVERAGE_REQUEST,
  POLICIES,
  COI_REVIEW,
  CPI_APPLICATIONS,
  CPI_APPLICATION,
  CPI_APPLICATION_V2,
  VENDORS,
};
Object.freeze(ROUTE_ROLE_ACCESS);

export default ROUTE_ROLE_ACCESS;
