import * as api from '@/api/proposal';
import logger from 'loglevel';

const state = {};
const mutations = {};

const actions = {
  async send({}, proposal) {
    const action = proposal.id ? api.updateProposal : api.createProposal;
    const data = await handleResponse(action, proposal);
    return data;
  },
  async recall({}, proposalId) {
    await handleResponse(api.recallProposal, proposalId);
  },
  async getProposal({}, appId) {
    const data = await handleResponse(api.getProposal, appId);
    return data;
  },
  async requestBindingOrder({}, payload) {
    const { proposalId, companyName, insuranceType, responseDate } = payload;

    try {
      const response = await api.requestBindingOrder(
        proposalId,
        companyName,
        insuranceType,
        responseDate
      );

      return response.data;
    } catch (err) {
      logger.error(err.response || err);
      throw err;
    }
  },
};

async function handleResponse(action, data) {
  try {
    const response = await action(data);
    return response.data;
  } catch (err) {
    logger.error(err.response || err);
    throw err;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
