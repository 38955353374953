import * as Api from '@/api/vendors';
import * as logger from 'loglevel';

logger.setDefaultLevel(process.env.VUE_APP_LOG_LEVEL || 'error');

const state = {
  pages: {},
  pageCount: null,
  pageSize: null,
};

const getters = {
  getPage: state => page => {
    return state.pages[page] ? Array.from(state.pages[page].values()) : [];
  },

  getVendorById: state => id =>
    Object.values(state.pages)
      .flatMap(vendorMap => [...vendorMap.values()])
      .find(vendor => vendor.vendorId === id),
};

const mutations = {
  updateVendorList(state, { page, vendors }) {
    const map = new Map();
    vendors.forEach(vendor => {
      map.set(vendor.id, vendor);
    });
    state.pages[page] = map;
    state.pages = { ...state.pages };
  },
  setPageCount(state, pageCount) {
    state.pageCount = pageCount;
  },
  setPageSize(state, pageSize) {
    state.pageSize = pageSize;
  },
};

const actions = {
  // IMPORTANT!! This is the call where we would need to change to api/vendors endpoint once it's done. (probably removing clientID)
  async getVendors({ commit }, params) {
    try {
      const response = await Api.getVendors(params);

      commit('setPageCount', response.data.pageCount);
      commit('setPageSize', response.data.pageSize);
      commit('updateVendorList', {
        page: response.data.currentPage,
        vendors: response.data.results,
      });
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
  async getVendor(context, vendorId) {
    try {
      const response = await Api.getVendor(vendorId);
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
  async searchVendors(query) {
    try {
      return await Api.searchVendors(query);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
  async getCompanyVendor(context, companyId) {
    try {
      const response = await Api.getCompanyVendor(companyId);
      return response.data;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
