import axios from 'axios';

// export function getCoverageRequestPolicies(params = {}) {
//   return axios.get('api/coverage-request-policies', { params });
// }

const baseUrl = 'api/insurance-policies';

export function uploadPDF({ file, correlationId }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('correlationId', correlationId);

  return axios.post(`${baseUrl}/vendor-policy`, formData, {
    headers: {
      'X-CORRELATION-ID': correlationId,
      'content-type': 'multipart/form-data',
    },
  });
}

export function getAllPolicies(params = {}) {
  return axios.get('api/v2/insurance-policies', { params });
}

export function getLobList() {
  return axios.get('api/companies/compliance-lobs');
}

export function getLob(name = '') {
  return axios.get(`api/LOB?name=${name}`);
}

export function uploadDocument(payload) {
  let formData = new FormData();
  formData.append(
    'file',
    new Blob([payload.file], { type: 'application/pdf' }),
    payload.file.name
  );
  formData.append('type', payload.type);
  formData.append('correlationId', payload.correlationId);

  const headers = {};
  if (payload.tenantId) headers['X-TENANT-ID'] = payload.tenantId;

  return axios.post('api/documents', formData, {
    headers,
  });
}

export function finalizePolicy(payload) {
  return axios.post(
    'api/insurance-policies',
    { ...payload.newPolicy },
    { headers: { 'X-TENANT-ID': payload.tenantId } }
  );
}

// export function finalizeQuote(quoteId) {
//   return axios.post(`api/quotes/${quoteId}/finalize`);
// }

export function getDocumentId(params) {
  return axios.get('api/documents', { params });
}

export function getDocument(documentId) {
  return axios.get(`api/documents/${documentId}/direct-download`, {
    responseType: 'blob',
  });
}

export function getPolicy(policyId) {
  return axios.get(`/api/insurance-policies/${policyId}`);
}

// export function getInsuranceCoverages(vendorId, params) {
//   return axios.get(`api/vendors/${vendorId}/insurance-coverages`, {
//     params,
//   });
// }

export function getVendor(vendorId) {
  return axios.get(`api/vendors/${vendorId}`);
}

export function getVendors() {
  return axios.get('api/vendors/get-vendors');
}

export function getClients() {
  return axios.get('api/clients/get-clients');
}

export function updateCoiStatus(policyId, status, rejectionReason) {
  const payload = { status: status.toUpperCase(), rejectionReason };
  return axios.post(`api/insurance-policies/${policyId}/coi-review`, payload);
}

export function uploadInsuranceCertificate({ file, policyId, tenantId }) {
  return uploadDocument({
    correlationId: policyId,
    type: 'COI',
    file: file,
    tenantId: tenantId,
  });
}

export function deleteInsurancePolicy(policyId) {
  return axios.delete(`api/insurance-policies/${policyId}`);
}
