const state = {
  selected: [],
};

const mutations = {
  setSelected(state, selected) {
    state.selected = selected || [];
  },
  clearSelected(state) {
    state.selected = [];
  },
};

const actions = {
  toggleSelected({ state, commit }, opportunity) {
    const { selected: $selected } = state;
    let selected = $selected.filter(s => s.id !== opportunity.id);
    if (selected.length === $selected.length) {
      selected = [...$selected, opportunity];
    }

    commit('setSelected', selected);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
