import axios from 'axios';
import store from '@/store/store';
import router from '@/router';
import { AUTH_ROUTE_CONSTANTS, getAccessToken } from '@aon/cfs-user';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  async config => {
    const token = await getAccessToken();

    if (token) config.headers.Authorization = `Bearer ${token}`;

    const tenantId = store.state.companies?.companyId;
    if (tenantId) config.headers['X-TENANT-ID'] = tenantId;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      router.push({ name: AUTH_ROUTE_CONSTANTS.LOGIN_REDIRECT });
    }

    return Promise.reject(error);
  }
);
