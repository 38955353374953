import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import { resetPlugin } from '@aon/cfs-components/src/store/plugins';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    featureFlags: {
      FF_PRI_REBRANDING:
        !!process.env.VUE_APP_FF_PRI_REBRANDING &&
        process.env.VUE_APP_FF_PRI_REBRANDING === 'true',
    },
  },
  mutations: {
    initializeStore(state) {
      const opportunities = localStorage.getItem('cpiOpportunities');
      if (opportunities) {
        this.replaceState(
          Object.assign(state, { cpiOpportunities: JSON.parse(opportunities) })
        );
      }
    },
  },
  actions: {},
  modules: { ...modules },
  plugins: [resetPlugin],
  strict: process.env.NODE_ENV !== 'production',
});

store.subscribe((mutation, state) => {
  if (mutation.type.startsWith('cpiOpportunities')) {
    localStorage.setItem(
      'cpiOpportunities',
      JSON.stringify(state.cpiOpportunities)
    );
  }
});

export default store;
