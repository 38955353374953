import INSURANCE_TYPES from '@aon/cfs-utils/lib/constants/insuranceTypes';
import {
  getCyberApplication,
  updateCyberApplication,
  getEoApplication,
  updateEoApplication,
  getGlApplication,
  updateGlApplication,
  getIpApplication,
  updateIpApplication,
  updateInsuranceApplicationStatus,
} from '@aon/cfs-insurance/src/api';
import {
  getIdentificationInformation,
  saveIdentificationInformation,
} from '@/api/coverageRequests';
import { insuranceTypeRouteMap } from '@/lib/mappings';

const state = {
  application: {},
  insuranceType: null,
  options: null,
  getEndpoint: null,
  updateEndpoint: null,
  tenantId: null,
};
const mutations = {
  setApplication(state, application) {
    state.application = {
      ...application,
      identificationInformation: state.application.identificationInformation,
    };
  },
  setApplicationIdentificationInfo(state, info) {
    state.application = {
      ...state.application,
      identificationInformation: info,
    };
  },
  setInsuranceType(state, insuranceType) {
    state.insuranceType = insuranceType;
  },
  setOptions(state, options) {
    state.options = options;
  },
  setEndpoints(state, payload) {
    state.getEndpoint = payload.getEndpoint;
    state.updateEndpoint = payload.updateEndpoint;
  },
  setTenantId(state, tenantId) {
    state.tenantId = tenantId;
  },
};
function insuranceTypeGetEndpointMap(type) {
  return {
    [INSURANCE_TYPES.CYBER]:
      type === 'get' ? getCyberApplication : updateCyberApplication,
    [INSURANCE_TYPES.ERRS_OMISNS]:
      type === 'get' ? getEoApplication : updateEoApplication,
    [INSURANCE_TYPES.GL]:
      type === 'get' ? getGlApplication : updateGlApplication,
    [INSURANCE_TYPES.IP]:
      type === 'get' ? getIpApplication : updateIpApplication,
  };
}

const actions = {
  async getApplication({ commit, dispatch, state }, payload) {
    commit('setTenantId', payload.tenantId);
    dispatch('setInsuranceType', payload.insuranceType);
    dispatch('setOptions', payload.tenantId);
    dispatch('setEndpoints');

    const options = state.options;
    const endpoint = state.getEndpoint;
    const { data } = await endpoint(payload.applicationId, null, options);

    commit('setApplication', data);
    return data;
  },
  async getApplicationAndIdentificationInfo({ dispatch }, payload) {
    return await Promise.all([
      dispatch('getApplication', payload),
      dispatch('getIdentificationInformation', payload),
    ]);
  },
  async getIdentificationInformation({ commit }, payload) {
    let data = {};
    try {
      const result = await getIdentificationInformation(payload.applicationId);
      data = result.data;
    } catch (error) {
      const { response } = error;
      if (response && response.status !== 404) throw error;
    }

    commit('setApplicationIdentificationInfo', data);
    return data;
  },
  async saveIdentificationInformation({ state, commit }, payload) {
    const applicationId = state.application.id;
    const tenantId = state.tenantId;

    await saveIdentificationInformation(applicationId, {
      tenantId,
      ...payload,
    });

    commit('setApplicationIdentificationInfo', payload);
  },
  async updateStatus({ commit, state }, status) {
    const application = { ...state.application };
    const options = state.options;
    const tenantId = state.tenantId;

    await updateInsuranceApplicationStatus(application.id, { status }, options);

    application.status = status;
    application.tenantId = tenantId;
    commit('setApplication', application);
  },
  setEndpoints({ commit }) {
    const payload = {
      getEndpoint: insuranceTypeGetEndpointMap('get')[state.insuranceType],
      updateEndpoint: insuranceTypeGetEndpointMap('update')[
        state.insuranceType
      ],
    };
    commit('setEndpoints', payload);
  },
  setInsuranceType({ commit }, routeType) {
    commit(
      'setInsuranceType',
      Object.entries(insuranceTypeRouteMap).find(
        type => routeType === type[1]
      )[0]
    );
  },
  setOptions({ commit }, tenantId) {
    commit('setOptions', {
      headers: { 'X-TENANT-ID': tenantId },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
