// import * as Api from '@aon/cfs-components/src/api/insurance';
// import * as Api from '@/api/coverageRequests';
import * as logger from 'loglevel';

logger.setDefaultLevel(process.env.VUE_APP_LOG_LEVEL || 'error');

const state = {
  coverageRequests: [],
};

const mutations = {
  setCoverageRequests(state, coverageRequests) {
    state.coverageRequests = coverageRequests;
  },
};

const actions = {
  // async getCoverageRequests({ commit }, filters) {
  //   try {
  //     const res = await Api.getCoverageRequests(filters);
  //
  //     commit('setCoverageRequests', res.data);
  //   } catch (err) {
  //     logger.error(err);
  //     throw err;
  //   }
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
