import * as Api from '@/api/insurancePolicies';
import * as logger from 'loglevel';

const state = {
  documentsData: [],
  documentId: null,
  policies: [],
  policy: null,
};
const mutations = {
  addDocumentsData(state, documentData) {
    if (
      !state.documentsData.some(
        d => d.correlationId === documentData.correlationId
      )
    ) {
      state.documentsData.unshift(documentData);
    }
  },
  addPolicy(state, policy) {
    if (!state.policies.some(p => p.id === policy.id)) {
      state.policies.unshift(policy);
    }
  },
  setPolicyCoiClientSubRequirements(state, coiClientSubRequirements) {
    if (state.policy) {
      state.policy.coiClientSubRequirements = coiClientSubRequirements;
    }
  },
  setPolicy(state, policy) {
    state.policy = policy;
  },
  setPolicies(state, policies) {
    state.policies = policies;
  },
  setDocumentsData(state, documentsData) {
    state.documentsData = documentsData;
  },
  setDocumentId(state, documentId) {
    state.documentId = documentId;
  },
};
const actions = {
  async finalizePolicy({}, payload) {
    await Api.uploadDocument({
      file: payload.coiFile,
      type: 'COI',
      correlationId: payload.applicationId,
      tenantId: payload.tenantId,
    });
    await Api.uploadDocument({
      file: payload.binderFile,
      type: 'BINDER',
      correlationId: payload.applicationId,
      tenantId: payload.tenantId,
    });
    await Api.finalizePolicy(payload);
    // await Api.finalizeQuote(payload.quoteId);
  },
  async getDocumentId({ commit }, policyId) {
    try {
      commit('setDocumentId', null);

      const response = await Api.getDocumentId({ correlationId: policyId });
      const coiData = response.data
        ?.filter(x => x.type === 'COI')
        ?.sort((a, b) => new Date(b.uploaded) - new Date(a.uploaded))[0];
      if (!coiData) {
        throw new Error('COI not found');
      }

      commit('setDocumentId', coiData.id);
      commit('addDocumentsData', coiData);

      return coiData.id;
    } catch (err) {
      logger.error(err.response || err);
      throw err;
    }
  },
  async getPolicy({ commit }, policyId) {
    try {
      const policy = await Api.getPolicy(policyId);

      commit('setPolicy', policy.data);
      commit('addPolicy', policy.data);

      return policy;
    } catch (err) {
      commit('setPolicy', null);
      logger.error(err.response || err);
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
