import axios from 'axios';

export function createProposal(proposal) {
  return axios.post('api/proposals', proposal);
}

export function updateProposal(proposal) {
  return axios.put('api/proposals', proposal);
}

export function recallProposal(proposalId) {
  return axios.put(`api/proposals/${proposalId}/recall`);
}

export function getProposal(appId) {
  return axios.get(`api/applications/${appId}/proposal`);
}

export function requestBindingOrder(
  proposalId,
  companyName,
  insuranceType,
  responseDate
) {
  const requestBody = { proposalId, companyName, insuranceType, responseDate };

  return axios.put(`api/proposals/${proposalId}/binding-order`, requestBody);
}
