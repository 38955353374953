import axios from 'axios';
import Qs from 'qs';

export const MAX_PAGE_SIZE = 10;

// IMPORTANT! This is the call where we need to change to the api/vendors endpoint when it's done
export function getVendors(params) {
  if (!params.pageSize) {
    params.pageSize = MAX_PAGE_SIZE;
  }

  return axios.get(`api/broker-vendors`, {
    params,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
}

export function getVendor(vendorId) {
  return axios.get(`api/vendors/${vendorId}`);
}

export function getCompanyVendor(companyId) {
  return axios.get(`api/companies/${companyId}/vendor`);
}

export function searchVendors(name) {
  return axios.get('api/broker-vendors', {
    params: { name },
  });
}

export function createFullVendor(vendor) {
  return axios.post('api/vendors/stand-alone', vendor);
}

export function searchCompanies(name) {
  return axios.get('api/companies/similar', { params: { name } });
}

export function getVendorClients(vendorId) {
  return axios.get(`api/vendors/${vendorId}/clients`);
}

export function updateVendor(vendor) {
  return axios.put(`api/vendors/${vendor.id}`, vendor);
}
