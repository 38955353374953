import CARRIERS from '@/lib/constants/carriers';
import * as logger from 'loglevel';

logger.setDefaultLevel(process.env.VUE_APP_LOG_LEVEL || 'error');

const state = {
  carriers: [],
};

const getters = {
  searchCarrier: state => query =>
    state.carriers.filter(
      car =>
        car.id === query ||
        car.aonid === query ||
        car.naic === query ||
        car.name?.toLowerCase().indexOf(query.toString().toLowerCase()) > -1 ||
        query === ''
    ),
};

const mutations = {
  setCarriers(state, carriers) {
    state.carriers = carriers;
  },
};

const actions = {
  async getAllCarriers({ commit }) {
    try {
      commit(
        'setCarriers',
        CARRIERS.map(el => ({
          ...el,
          id: el.aonid,
        }))
      );
    } catch (error) {
      logger.error(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
