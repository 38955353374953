import Vue from 'vue';
import { getDateFromUTC } from '@aon/cfs-utils';

Vue.filter('longDate', value => {
  if (value) {
    return getDateFromUTC(value);
  }
});

Vue.filter('currency', value => {
  if (value) {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
});
