import carriers from './carriers';
import coverageRequests from './coverageRequests';
import cpiApplications from '@/store/modules/cpiApplications';
import insuranceApplication from './insuranceApplication';
import insurancePolicy from '@/store/modules/insurancePolicy';
import markets from './markets';
import proposal from './proposal';
import quotes from './quotes';
import vendors from './vendors';
import cpiOpportunities from './cpiOpportunities';
import contracts from './contracts';

export default {
  carriers,
  coverageRequests,
  cpiApplications,
  insuranceApplication,
  insurancePolicy,
  markets,
  proposal,
  quotes,
  cpiOpportunities,
  vendors,
  contracts,
};
