import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import store from '@/store/store';
import routerAuthMiddleware from '@aon/cfs-user/src/router/middleware';
import { requiresRole } from '@/router/middleware';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  const middleware = [...routerAuthMiddleware, requiresRole];

  for (let i = 0; i < middleware.length; i++) {
    const middlewareResponse = await middleware[i](to, from, next, store);
    if (middlewareResponse) return next(middlewareResponse);
  }

  return next();
});

export default router;
