import axios from 'axios';

const baseUrl = '/api/cpi-applications';

export function listCpiApplications(queryParams) {
  return axios.get(baseUrl, { params: queryParams });
}

export function getCpiApplication(applicationId) {
  return axios.get(`${baseUrl}/${applicationId}`);
}

export function createCpiApplication(application) {
  return axios.post(`${baseUrl}`, application);
}

export function updateCpiApplication(applicationId, application) {
  return axios.put(`${baseUrl}/${applicationId}`, application);
}

export function deleteCpiApplication(applicationId) {
  return axios.delete(`${baseUrl}/${applicationId}`);
}

export function submitApplication(applicationId) {
  return axios.post(`${baseUrl}/${applicationId}/submit`);
}

export function finalizeApplication(applicationId, status, tier) {
  return axios.post(
    `${baseUrl}/${applicationId}/review/${status}`,
    {},
    { params: { tier } }
  );
}

export function archiveCpiApplication(applicationId) {
  return axios.post(`${baseUrl}/${applicationId}/archive`);
}
